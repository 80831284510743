<template>
    <validation-observer tag="div" ref="observer">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <b-row>
            <b-col cols="6">
                <b-form-group :label="$t(labelPrefix+'not_shipped_statuses')">
                    <multiselect v-model="form.not_shipped_statuses"
                                 name="not_shipped_statuses"
                                 :searchable="true"
                                 :options="Object.keys(getStatuses())"
                                 :multiple="true"
                                 select-label=""
                    ></multiselect>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group :label="$t(labelPrefix+'shipped_statuses')">
                    <multiselect v-model="form.shipped_statuses"
                                 name="shipped_statuses"
                                 :searchable="true"
                                 :options="Object.keys(getStatuses())"
                                 :multiple="true"
                                 select-label=""
                    ></multiselect>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <zw-switch-group v-model="form.copy_combine_to_separate_dir"
                                 name="copy_combine_to_separate_dir"
                                 :label-prefix="labelPrefix"
                ></zw-switch-group>
            </b-col>
            <b-col cols="6">
                <zw-switch-group v-model="form.merge_combine_files"
                                 name="merge_combine_files"
                                 :label-prefix="labelPrefix"
                ></zw-switch-group>
            </b-col>
            <b-col cols="6">
                <zw-switch-group v-model="form.create_second_combine_file"
                                 name="create_second_combine_file"
                                 :label-prefix="labelPrefix"
                ></zw-switch-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col sm="12" class="text-xs-center mt-2">
                <b-row align-h="end">
                    <b-col sm="6" class="text-sm-right">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </b-col>

        </b-row>
    </validation-observer>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'SettingsShipping',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            defaultForm: {
                not_shipped_statuses: '',
                shipped_statuses: '',
            },
            form: {},
            labelPrefix: 'settings.shipping.label.',
        }
    },
    methods: {
        ...mapGetters('Settings', ['getShipping']),
        ...mapGetters('CommonData', ['getStatuses']),
        shown() {
            this.loading = true
            const settingsShipping = this.$store.dispatch('Settings/fetchShipping');

            Promise.all([settingsShipping]).then(() => {
                this.form = JSON.parse(JSON.stringify({...this.defaultForm, ...this.getShipping()}))

                this.loading = false
            });
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.$store.dispatch('Settings/save', this.form).then((response) => {
                        this.afterSave(response)
                    }).catch(errors => {
                        this.$refs['observer'].setErrors(errors)
                        this.showValidationError()
                    })
                } else {
                    this.showValidationError()
                }
            })
        },
        afterSave(response) {
            if (response.status == 200 || response.status == 201) {
                this.$root.showMessage(
                    this.$t('common.form.title.success'),
                    this.$t('common.toasts.success_updated'),
                    'success'
                )
                this.shown()
            }
            this.$root.afterSettingsUpdate()
        },
    },
    mounted() {
        this.shown()
    }
}
</script>